import _remove from "lodash/remove";
import _keyBy from "lodash/keyBy";
import * as ResourceActions from "actions/resources";
import reducerWithActionMap from "../utils/reducerWithActionMap";

const initialState = {
  resourceList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    resourceMap: {},
  },
  mapResources: {
    isFetching: false,
    isFetched: false,
    listData: [],
  },
};

const setResourceListFetching = (fetching) => (state) => ({
  ...state,
  resourceList: {
    ...state.resourceList,
    isFetching: fetching,
  },
});

const setResourceList = (state, { payload: { listData } }) => {
  const resourceMap = _keyBy(listData.gameResources, 'id');
  return {
    ...state,
    resourceList: {
      ...state.resourceList,
      listData,
      resourceMap,
      isFetching: false,
      isFetched: true,
    },
  };
};

const setMapFetching = (fetching) => (state) => ({
  ...state,
  mapResources: {
    ...state.mapResources,
    isFetching: fetching,
  },
});

const setMapList = (state, { payload: { listData } }) => ({
  ...state,
  mapResources: {
    ...state.mapResources,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const actionMap = {
  [ResourceActions.LIST_RESOURCES_REQUEST]: setResourceListFetching(true),
  [ResourceActions.LIST_RESOURCES_SUCCESS]: setResourceList,
  [ResourceActions.LIST_RESOURCES_FAILURE]: setResourceListFetching(false),

  [ResourceActions.FETCH_RESOURCE_MAP_REQUEST]: setMapFetching(true),
  [ResourceActions.FETCH_RESOURCE_MAP_SUCCESS]: setMapList,
  [ResourceActions.FETCH_RESOURCE_MAP_FAILURE]: setMapFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);
