import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";

import { signup } from "actions/user";
import { openModal, closeModal } from "actions/modal";

import { SIGNUP_MODAL_ID } from "consts/modalIds";
import ConnectedDialog from "components/ConnectedDialog";
import x from "img/x.svg";

const signupSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("invalid_email")
    .required("required_field"),
  password: Yup.string()
    .min(5, "add_5_characters")
    .required("required_field"),
});

const SignupModal = ({ signup, openModal, closeModal, modalId }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const closeSignupModal = () => {
    closeModal(SIGNUP_MODAL_ID);
  };

  const registerUser = async (values) => {
    try {
      await signup(values);
      alert(t("registration_success_check_email"));
      closeSignupModal();
    } catch (e) {
      console.log(e);
      alert(t("registration_failed"));
    }
  };

  return (
    <ConnectedDialog id={SIGNUP_MODAL_ID}>
      <div className="fixed left-0 z-50 top-0 w-full h-full overflow-auto bg-black bg-opacity-25">
        <div className="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 m-0 mx-auto p-6 border-2 bg-white rounded-lg bg-white font-mont p-5">
          <div className="relative rounded-lg bg-white font-mont p-5">
            <div className="absolute top-0 right-0">
              <button
                className="focus:outline-none"
                onClick={() => {
                  closeSignupModal();
                }}
              >
                <img src={x} className="w-5 h-5 focus:outline-none" />
              </button>
            </div>
            <div
              className="text-center font-bold text-blue mb-10 text-lg"
              onClose={closeSignupModal}
            >
              {t("registration")}
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={signupSchema}
              validateOnChange={false}
              onSubmit={registerUser}
            >
              <Form className="w-72 rounded" autoComplete="on">
                <label className="text-sm text-left">{t("email")}</label>
                <Field
                  name="email"
                  id="email"
                  placeholder={t("insert_email")}
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightBlue focus:border-transparent"
                />
                <br />
                <ErrorMessage name='email' render={(error) => t(error)} />
                <br />
                <label className="text-sm text-left">{t("password")}</label>
                <Field
                  name="password"
                  id="password"
                  placeholder={t("insert_password")}
                  type="password"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightBlue focus:border-transparent"
                />
                <br />
                <ErrorMessage name='password' render={(error) => t(error)} />
                <div className="text-center">
                  <button
                    className="mt-5 bg-lightBlue py-2 px-10 text-white font-bold border-2 border-lightBlue rounded-md focus:outline-none"
                    type="submit"
                  >
                    {t("register")}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};

const mapDispatchToProps = {
  signup,
  openModal,
  closeModal,
};

export default connect(null, mapDispatchToProps)(SignupModal);
