import useSound from "use-sound";
import React, { useCallback, useEffect, useRef } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { CHAT_CREATED } from "actions/chat";
import { getUserDetails } from "selectors/user";

import popSound from "sfx/pop.ogg";

export const SocketContext = React.createContext(null);

const ConnectedSocketProvider = ({ userDetails, dispatch, children }) => {
  const socket = useRef();
  const [popSfx] = useSound(popSound);
  const jwt = typeof window !== "undefined" && localStorage.getItem("jwt");

  const shouldReconnect = jwt;

  const connectSocket = useCallback(() => {
    socket.current = new WebSocket(
      `${process.env.RAZZLE_GAME_WS_URL}?token=${jwt}`
    );
    socket.current.request = (payload) => {
      const reqId = Math.random();
      payload = {
        ...payload,
        data: {
          ...payload.data,
          id: reqId,
        },
      };
      return new Promise((resolve, reject) => {
        const listener = (message) => {
          socket.current.removeEventListener("message", listener);
          const { data } = message;
          try {
            const messageData = JSON.parse(data);
            if (messageData && _get(messageData, "data.id", -1) == reqId) {
              clearTimeout(timeoutId);
              resolve(messageData);
            }
          } catch (e) { }
        };
        socket.current.addEventListener("message", listener);
        const timeoutId = setTimeout(() => {
          socket.current.removeEventListener("message", listener);
          reject("timeout");
        }, 5000);
        socket.current.send(JSON.stringify(payload));
      });
    };
    socket.current.addEventListener("message", (message) => {
      const { data } = message;
      if (data === "ping") {
        socket.current.send("pong");
      } else {
        const messageData = JSON.parse(data);
        if (messageData.channel) {
          const reduxMessage = {
            type: `${messageData.channel}@${messageData.data.action}`,
            data: messageData.data,
          };
          dispatch(reduxMessage); // Pass message into redux
          if (reduxMessage.type === CHAT_CREATED) {
            popSfx();
          }
          if (reduxMessage.type === CHAT_CREATED) {
            popSfx();
          }
        }
      }
    });
    socket.current.addEventListener("close", () => {
      if (shouldReconnect) {
        connectSocket();
      }
    });
  }, [shouldReconnect]);

  useEffect(() => {
    let jwt;
    if (window) {
      jwt = localStorage.getItem("jwt");
    }
    if (jwt) {
      if (socket.current == null) {
        connectSocket();
      }
    } else {
      if (socket.current) {
        socket.current.close();
      }
      socket.current = null;
    }
  }, [userDetails.userData]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
});

export default connect(mapStateToProps)(ConnectedSocketProvider);
