import { combineReducers } from "redux";
import blog from "./blog";
import user from "./user";
import modal from "./modal";
import game from "./game";
import resource from "./resource";
import chat from "./chat";

const rootReducer = combineReducers({
  blog,
  user,
  modal,
  game,
  resource,
  chat,
});

export default rootReducer;
