import * as ChatActions from "actions/chat";
import reducerWithActionMap from "../utils/reducerWithActionMap";

const initialState = {
  isAuthenticated: false,
  messageList: {
    isPosting: false,
    isFetching: false,
    isFetched: false,
    listData: {
      count: 0,
      chats: [],
    },
  },
};

const setMessageListFetching = (fetching) => (state) => ({
  ...state,
  messageList: {
    isFetching: fetching,
  },
});

const setMessageList = (state, { payload: { listData } }) => ({
  ...state,
  messageList: {
    ...state.messageList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setMessagePosting = (posting) => (state) => ({
  ...state,
  messageList: {
    ...state.messageList,
    isPosting: posting,
  },
});

const addMessage = (state, { data }) => ({
  ...state,
  messageList: {
    ...state.messageList,
    listData: {
      count: state.messageList.count + 1,
      chats: [...state.messageList.listData.chats, data],
    },
    isPosting: false,
  },
});

const actionMap = {
  [ChatActions.LIST_REQUEST]: setMessageListFetching(true),
  [ChatActions.LIST_FAILURE]: setMessageListFetching(false),
  [ChatActions.LIST_SUCCESS]: setMessageList,

  [ChatActions.CREATE_CHAT_REQUEST]: setMessagePosting(true),
  [ChatActions.CREATE_CHAT_FAILURE]: setMessagePosting(false),
  [ChatActions.CREATE_CHAT_SUCCESS]: addMessage,

  [ChatActions.CHAT_CREATED]: addMessage,
};

export default reducerWithActionMap(actionMap, initialState);
