import getAxiosInstance from '../config/http';

export const LIST_RESOURCES_REQUEST = 'RESOURCE@LIST_RESOURCES_REQUEST';
export const LIST_RESOURCES_SUCCESS = 'RESOURCE@LIST_RESOURCES_SUCCESS';
export const LIST_RESOURCES_FAILURE = 'RESOURCE@LIST_RESOURCES_FAILURE';

export const listResources = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_RESOURCES_REQUEST,
  });
  try{
    const gamesData = await http.get('/game-resources', {params});
    dispatch({
      type: LIST_RESOURCES_SUCCESS,
      payload: {
        listData: gamesData.data,
      }
    });
  } catch(e){
    dispatch({
      type: LIST_RESOURCES_FAILURE,
    });
  };
};


export const FETCH_RESOURCE_MAP_REQUEST = 'RESOURCE@LIST_MAP_REQUEST';
export const FETCH_RESOURCE_MAP_SUCCESS = 'RESOURCE@LIST_MAP_SUCCESS';
export const FETCH_RESOURCE_MAP_FAILURE = 'RESOURCE@LIST_MAP_FAILURE';

export const fetchDiceResourceMap = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_RESOURCE_MAP_REQUEST,
  });
  try{
    const gamesData = await http.get('/game-dice-position-map', {params});
    dispatch({
      type: FETCH_RESOURCE_MAP_SUCCESS,
      payload: {
        listData: gamesData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_RESOURCE_MAP_FAILURE,
    });
  };
};
