import React from "react";

import { asyncComponent } from "@jaredpalmer/after";

export default [
  {
    path: "/",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Home"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/instructions",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Instructions"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/blogs",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Blogs/BlogListPage"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/blogs/:blogId",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Blogs/BlogDetails"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/reset",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/ResetPassword"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/verify",
    // exact: true,
    component: asyncComponent({
      loader: () => import("./pages/VerifyAccount"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/privacyPolicy",
    // exact: true,
    component: asyncComponent({
      loader: () => import("./pages/PrivacyPolicy"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/info/1",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Blogs/Blog1"), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: "/info/2",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Blogs/Blog2"), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: "/games",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Games/GamesListPage"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/addGame",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Games/AddGame"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/leaderboard",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Leaderboard"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/editProfile",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/EditProfile"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/profile",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Profile"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/game/:gameId",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Game/Dashboard"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/lobby/:gameId",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Games/GameLobby"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/results/:gameId",
    // exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Games/GameResults"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/users",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Users/UserListPage"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/sessions",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Sessions/SessionsListPage"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/leaderboardPeriod",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/LeaderboardListPage"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
];
