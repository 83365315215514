import _get from 'lodash/get';

export const getUserAuthenticated = state => state.user.isAuthenticated;
export const getUserDetails = state => state.user.userDetails;
export const getProfileData = state => {
  return state.user.profileData;
}
export const getUserList = state => state.user.userList;
export const getRankList = state => state.user.rankList;
export const isUserAdmin = state => {
  const userDetails = getUserDetails(state);
  return (userDetails.userData && userDetails.userData.role === 'ADMIN');
};
