import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "actions/user";
import { openModal, closeModal } from "actions/modal";

import {
  LOGIN_MODAL_ID,
  SIGNUP_MODAL_ID,
  FORGOT_PASSWORD_MODAL_ID,
} from "consts/modalIds";
import ForgotPasswordModal from "components/Auth/ForgotPasswordModal";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import ConnectedDialog from "components/ConnectedDialog";
import x from "img/x.svg";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("invalid_email")
    .required("required_field"),
  password: Yup.string()
    .min(5, "add_5_characters")
    .required("required_field"),
  rememberme: Yup.bool().default(false),
});

const EmailLoginModal = ({ login, openModal, closeModal, modalId }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const closeLoginModal = () => {
    closeModal(LOGIN_MODAL_ID);
  };

  const openSignupModal = () => {
    closeLoginModal();
    openModal(SIGNUP_MODAL_ID);
  };

  const openForgotPasswordModal = () => {
    closeLoginModal();
    openModal(FORGOT_PASSWORD_MODAL_ID);
  };

  const loginUser = async (values) => {
    let user = null;
    try {
      user = await login(values);
      closeLoginModal();
      if (!user.registrationCompleted) {
        history.push("/editProfile");
      } else {
        history.push("/games");
      }
    } catch (e) {
      console.log(e);
      alert(t("invalid_login"));
      throw e;
    }
  };

  return (
    <ConnectedDialog id={LOGIN_MODAL_ID}>
      <div className="fixed left-0 z-50 top-0 w-full h-full overflow-auto bg-black bg-opacity-25">
        <div className="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 m-0 mx-auto p-6 border-2 bg-white rounded-lg bg-white font-mont p-5">
          <div className="relative rounded-lg bg-white font-mont p-5">
            <div className="absolute top-0 right-0">
              <button
                className="focus:outline-none"
                onClick={() => {
                  closeLoginModal();
                }}
              >
                <img src={x} className="w-5 h-5" />
              </button>
            </div>
            <div className="">
              <div
                className="text-center font-bold text-blue text-lg mb-7"
                onClose={closeLoginModal}
              >
                {t("login_with_email")}
              </div>
              <Formik
                initialValues={{ email: "", password: "", rememberme: false }}
                validationSchema={LoginSchema}
                validateOnChange={false}
                onSubmit={loginUser}
              >
                {({ isSubmitting }) => (
                  <Form className="w-72 rounded" autoComplete="on">
                    <label className="text-sm text-left">{t("email")}</label>
                    <Field
                      name="email"
                      id="email"
                      placeholder={t("insert_email")}
                      className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightBlue focus:border-transparent"
                    />
                    <ErrorMessage name='email' render={(error) => t(error)} />
                    <br />
                    <label className="text-sm text-left">{t("password")}</label>
                    <Field
                      type="password"
                      name="password"
                      placeholder={t("insert_password")}
                      className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightBlue focus:border-transparent"
                    />
                    <ErrorMessage name='password' render={(error) => t(error)} />
                    <br />
                    <label className="text-sm">
                      <Field
                        type="checkbox"
                        name="rememberme"
                        className="mr-2"
                      />
                      {t("remember_me")}
                    </label>
                    <div className="mt-5">
                      <button
                        className="bg-blue px-10 py-2 text-white font-bold rounded-md border-2 border-blue text-center focus:outline-none disabled:opacity-50"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {t("login")}
                      </button>
                    </div>
                    <div className="mt-7 text-left text-sm flex flex-row">
                      <div className="font-mont">{t("forgot_password")}</div>
                      <div>
                        <button
                          className="font-mont text-blue focus:outline-none ml-1"
                          type="button"
                          onClick={openForgotPasswordModal}
                        >
                          {t("here")}
                        </button>
                      </div>
                    </div>
                    <hr className="mt-7 text-lightBlue" />
                    <div className="mt-7 font-mont text-left text-sm flex flex-row flex-wrap">
                      <div>{t("no_account")}</div>
                      <div>{t("register")} </div>
                      <div>
                        <button
                          className="text-blue focus:outline-none ml-1"
                          type="button"
                          onClick={openSignupModal}
                        >
                          {t("here")}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <ForgotPasswordModal />
            </div>
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};

const mapDispatchToProps = {
  login,
  openModal,
  closeModal,
};

export default connect(null, mapDispatchToProps)(EmailLoginModal);
