import React, { Children } from "react";
import { connect } from "react-redux";
import { closeModal, openModal } from "actions/modal";
import { isModalOpen } from "selectors/modal";

const ConnectedDialog = ({ isOpen, children, className }) => {
  return <>{isOpen && <div className={className}>{children}</div>}</>;
};

const mapStateToProps = (state, { id }) => ({
  isOpen: isModalOpen(state, id),
});

export default connect(mapStateToProps, null)(ConnectedDialog);
