import * as BlogActions from 'actions/blog';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  blogList: {
    isFetching: false,
    isFetched: false,
    listData: [],
  },
  blogDetails: {
    isFetching: false,
    isFetched: false,
    blogData: null
  }
};

// LIST BLOG DATA

const setBlogListFetching = fetching => state => ({
  ...state,
  blogList: {
    ...state.blogList,
    isFetching: fetching,
  },
});

const setBlogList = (state, { payload: { listData } }) => ({
  ...state,
  blogList: {
    ...state.blogList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});


const setBlogDetailsFetching = fetching => state => ({
  ...state,
  blogDetails: {
    ...state.blogDetails,
    isFetching: fetching,
  },
});

const setBlogDetails = (state, { payload: { blogData } }) => ({
  ...state,
  blogDetails: {
    ...state.blogDetails,
    blogData,
    isFetching: false,
    isFetched: true,
  },
});

const actionMap = {
  [BlogActions.LIST_REQUEST]: setBlogListFetching(true),
  [BlogActions.LIST_FAILURE]: setBlogListFetching(false),
  [BlogActions.LIST_SUCCESS]: setBlogList,
  [BlogActions.FETCH_REQUEST]: setBlogDetailsFetching(true),
  [BlogActions.FETCH_FAILURE]: setBlogDetailsFetching(false),
  [BlogActions.FETCH_SUCCESS]: setBlogDetails,
};

export default reducerWithActionMap(actionMap, initialState);
