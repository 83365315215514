import "./client.css";
import React, { Suspense, useEffect } from "react";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ensureReady, After, getSerializedData } from "@jaredpalmer/after";
// import { ThemeProvider } from '@material-ui/styles';
// import theme from './theme';
import routes from "./routes";
import configureStore from "./store/configureStore";
import Layout from "./Layout";
import { useSSR } from "react-i18next";
import "./i18n";

const preloadedState = getSerializedData("preloaded_state");
const store = configureStore(preloadedState);

const Main = ({ data }) => {
  useSSR(window.initialI18nStore, window.initialLanguage);
  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <Suspense fallback={'Loading...'}>
      <Provider store={store}>
        <BrowserRouter>
          <Layout>
            <After data={data} routes={routes} store={store} />
          </Layout>
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};

const renderApp = () => {
  ensureReady(routes).then((data) => {
    return hydrate(<Main data={data} />, document.getElementById("root"));
  });
};

renderApp();

if (module.hot) {
  module.hot.accept("./routes", renderApp);
}
