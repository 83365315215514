import React from "react";
import { useTranslation } from "react-i18next";
import { loginToken, fetchUser } from "actions/user";

import { SOCIAL_LOGIN_MODAL_ID, LOGIN_MODAL_ID } from "consts/modalIds";
import { openModal, closeModal } from "actions/modal";
import { Login } from "react-facebook";
import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ConnectedDialog from "components/ConnectedDialog";

import GoogleIcon from "img/google_logo.svg";
import FacebookIcon from "img/facebook.svg";
import x from "img/x.svg";

const SocialMediaLoginModal = ({
  openModal,
  closeModal,
  loginToken,
  modalId,
  fetchUser,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClose = () => {
    closeModal(SOCIAL_LOGIN_MODAL_ID);
  };

  const handleFbResponse = async (data) => {
    const user = await loginToken("facebook", data.tokenDetail.accessToken);
    handleClose();
    if (!user.registrationCompleted) {
      history.push("/editProfile");
    }
    fetchUser();
  };

  const handleGoogleResponse = async (data) => {
    const user = await loginToken("google", data.accessToken);
    handleClose();
    if (!user.registrationCompleted) {
      history.push("/editProfile");
    } else {
      history.push("/games");
    }
    fetchUser();
  };

  const emailLoginClicked = () => {
    handleClose();
    openModal(LOGIN_MODAL_ID);
  };

  return (
    <ConnectedDialog id={SOCIAL_LOGIN_MODAL_ID}>
      <div className="fixed left-0 z-50 top-0 w-full h-full overflow-auto bg-black bg-opacity-25">
        <div className="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 m-0 mx-auto p-6 border-2 bg-white rounded-lg bg-white font-mont p-5">
          <div className="relative rounded-lg bg-white font-mont p-5">
            <div className="absolute top-0 right-0">
              <button
                className="focus:outline-none"
                onClick={() => {
                  handleClose(modalId);
                }}
              >
                <img src={x} className="w-5 h-5 focus:outline-none" />
              </button>
            </div>
            <div className="text-center">
              <div className="font-bold text-blue text-lg">{t("login")}</div>
              <div>
                <button
                  className="mt-5 bg-lightBlue py-2 px-10 text-white font-bold border-2 border-lightBlue rounded-md focus:outline-none"
                  type="submit"
                  onClick={emailLoginClicked}
                >
                  {t("continue_with_email")}
                </button>
              </div>
              <div>
                <Login scope="email" onCompleted={handleFbResponse}>
                  {({ loading, handleClick, error, data }) => (
                    <button
                      className="mt-5 bg-facebook py-2 px-7 text-white font-bold border-2 border-facebook rounded-md focus:outline-none"
                      onClick={handleClick}
                      disabled={loading}
                    >
                      {t("continue_with_fb")}
                    </button>
                  )}
                </Login>
              </div>
              <div>
                <GoogleLogin
                  clientId={process.env.RAZZLE_GOOGLE_ID}
                  cookiePolicy={"single_host_origin"}
                  onSuccess={handleGoogleResponse}
                  onFailure={handleGoogleResponse}
                  render={({ onClick, disabled }) => (
                    <button
                      onClick={onClick}
                      disabled={disabled}
                      className="mt-5 bg-google py-2 px-10 text-white font-bold border-2 border-google rounded-md focus:outline-none"
                    >
                      {t("continue_with_gmail")}
                    </button>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};

const mapDispatchToProps = {
  loginToken,
  openModal,
  closeModal,
  fetchUser,
};

export default connect(null, mapDispatchToProps)(SocialMediaLoginModal);
