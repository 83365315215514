import getAxiosInstance from "../config/http";

export const LIST_GAME_REQUEST = "GAME@LIST_GAME_REQUEST";
export const LIST_GAME_SUCCESS = "GAME@LIST_GAME_SUCCESS";
export const LIST_GAME_FAILURE = "GAME@LIST_GAME_FAILURE";

export const listGames = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_GAME_REQUEST,
  });
  try {
    const gamesData = await http.get("/games", {params});
    dispatch({
      type: LIST_GAME_SUCCESS,
      payload: {
        listData: gamesData.data,
        count: gamesData.data.count
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_GAME_FAILURE,
    });
  }
};

export const LIST_LEADERBOARD_REQUEST = "LEADERBOARD@LIST_LEADERBOARD_REQUEST";
export const LIST_LEADERBOARD_SUCCESS = "LEADERBOARD@LIST_LEADERBOARD_SUCCESS";
export const LIST_LEADERBOARD_FAILURE = "LEADERBOARD@LIST_LEADERBOARD_FAILURE";

export const listLeaderboard = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_LEADERBOARD_REQUEST,
  });
  try {
    const leaderboardData = await http.get("/leaderboard/period", {params});
    dispatch({
      type: LIST_LEADERBOARD_SUCCESS,
      payload: {
        listData: leaderboardData.data,
        count: leaderboardData.data.count
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_LEADERBOARD_FAILURE,
    });
  }
};

export const CREATE_GAME_REQUEST = "GAME@CREATE_GAME_REQUEST";
export const CREATE_GAME_SUCCESS = "GAME@CREATE_GAME_SUCCESS";
export const CREATE_GAME_FAILURE = "GAME@CREATE_GAME_FAILURE";

export const createGame = (data) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_GAME_REQUEST,
  });
  try {
    const gameData = await http.post("/games", data);
    dispatch({
      type: CREATE_GAME_SUCCESS,
      payload: {
        listData: gameData.data,
      },
    });
    return gameData.data;
  } catch (e) {
    dispatch({
      type: CREATE_GAME_FAILURE,
    });
  }
};

export const FETCH_GAME_REQUEST = "GAME@FETCH_GAME_REQUEST";
export const FETCH_GAME_SUCCESS = "GAME@FETCH_GAME_SUCCESS";
export const FETCH_GAME_FAILURE = "GAME@FETCH_GAME_FAILURE";

export const fetchGame = (gameId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_GAME_REQUEST,
  });
  try {
    const gameData = await http.get(`/games/${gameId}`);
    dispatch({
      type: FETCH_GAME_SUCCESS,
      payload: {
        gameData: gameData.data,
      },
    });
    return gameData;
  } catch (e) {
    dispatch({
      type: FETCH_GAME_FAILURE,
    });
    throw e;
  }
};

export const UPDATE_GAME_REQUEST = "GAME@UPDATE_GAME_REQUEST";
export const UPDATE_GAME_SUCCESS = "GAME@UPDATE_GAME_SUCCESS";
export const UPDATE_GAME_FAILURE = "GAME@UPDATE_GAME_FAILURE";

export const updateGame = (id, gameData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_GAME_REQUEST,
  });
  try {
    const data = await http.put(`/games/${id}`, gameData);
    dispatch({
      type: UPDATE_GAME_SUCCESS,
      payload: {
        gameData: data,
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_GAME_FAILURE,
    });
  }
};

export const DELETE_GAME_REQUEST = "GAME@DELETE_GAME_REQUEST";
export const DELETE_GAME_SUCCESS = "GAME@DELETE_GAME_SUCCESS";
export const DELETE_GAME_FAILURE = "GAME@DELETE_GAME_FAILURE";

export const deleteGame = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_GAME_REQUEST,
  });
  try {
    const { data } = await http.delete(`/games/${id}`);
    dispatch({
      type: DELETE_GAME_SUCCESS,
      payload: {
        gameId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_GAME_FAILURE,
    });
  }
};

export const JOIN_GAME_REQUEST = "GAME@JOIN_GAME_REQUEST";
export const JOIN_GAME_SUCCESS = "GAME@JOIN_GAME_SUCCESS";
export const JOIN_GAME_FAILURE = "GAME@JOIN_GAME_FAILURE";

export const joinGame = (id, joinData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: JOIN_GAME_REQUEST,
  });
  try {
    const { data } = await http.post(`/games/${id}/join`, joinData);
    dispatch({
      type: JOIN_GAME_SUCCESS,
      payload: {
        gameId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: JOIN_GAME_FAILURE,
    });
    return null;
  }
};

export const LEAVE_GAME_REQUEST = "GAME@LEAVE_GAME_REQUEST";
export const LEAVE_GAME_SUCCESS = "GAME@LEAVE_GAME_SUCCESS";
export const LEAVE_GAME_FAILURE = "GAME@LEAVE_GAME_FAILURE";

export const leaveGame = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LEAVE_GAME_REQUEST,
  });
  try {
    const { data } = await http.post(`/games/${id}/leave`);
    dispatch({
      type: LEAVE_GAME_SUCCESS,
      payload: {
        gameId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: LEAVE_GAME_FAILURE,
    });
  }
};

export const START_GAME_REQUEST = "GAME@START_GAME_REQUEST";
export const START_GAME_SUCCESS = "GAME@START_GAME_SUCCESS";
export const START_GAME_FAILURE = "GAME@START_GAME_FAILURE";

export const startGame = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: START_GAME_REQUEST,
  });
  try {
    const { data } = await http.put(`/games/${id}/start`);
    dispatch({
      type: START_GAME_SUCCESS,
      payload: {
        gameId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: START_GAME_FAILURE,
    });
    throw e;
  }
};

export const GAME_RESULTS_REQUEST = "GAME@GAME_RESULTS_REQUEST";
export const GAME_RESULTS_SUCCESS = "GAME@GAME_RESULTS_SUCCESS";
export const GAME_RESULTS_FAILURE = "GAME@GAME_RESULTS_FAILURE";

export const listGameResults = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: GAME_RESULTS_REQUEST,
  });
  try {
    const { data } = await http.get(`/games/${id}/game-state`);
    dispatch({
      type: GAME_RESULTS_SUCCESS,
      payload: {
        gameRes: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: GAME_RESULTS_FAILURE,
    });
    throw e;
  }
};

export const SYNC = "GAME@SYNC";
export const GAME_LOBBY_JOIN = "GAME_LOBBY@JOIN";
export const GAME_LOBBY_LEAVE = "GAME_LOBBY@LEAVE";
export const GAME_REMOVED = "GAME_LIST@REMOVED";
export const GAME_ADDED = "GAME_LIST@ADDED";
export const GAME_UPDATED = "GAME_LIST@UPDATED";
