import * as UserActions from 'actions/user';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  userList: {
    isFetching: false,
    isFetched: false,
    listData: [],
  },
  userDetails: {
    isFetching: true,
    isFetched: false,
    userData: [],
  },
  // profileData: {
  //   isFetching: false,
  //   isFetched: false,
  //   profile: null,
  // },
  rankList: {
    isFetching: false,
    isFetched: false,
    rankData: null,
  }
};

// SELF USER DATA

const setUserDataFetching = fetching => state => ({
  ...state,
  userDetails: {
    ...state.userDetails,
    isFetching: fetching,
  },
});

const setUserData = (state, { payload: { userData } }) => ({
  ...state,
  isAuthenticated: true,
  userDetails: {
    ...state.userDetails,
    userData,
    isFetching: false,
    isFetched: true,
  },
});

const clearUserData = state => ({
  ...state,
  isAuthenticated: false,
  userDetails: {
    userData: null,
    isFetching: false,
    isFetched: false,
  },
});

// PROFILE USER DATA

// const setProfileDataFetching = fetching => state => ({
//   ...state,
//   profileData: {
//     ...state.profileData,
//     isFetching: fetching,
//   },
// });

// const setProfileData = (state, { payload: { profile } }) => ({
//   ...state,
//   isAuthenticated: true,
//   profileData: {
//     ...state.profileData,
//     profile,
//     isFetching: false,
//     isFetched: true,
//   },
// });

// LIST USER DATA

const setUserListFetching = fetching => state => ({
  ...state,
  userList: {
    ...state.userList,
    isFetching: fetching,
  },
});

const setUserList = (state, { payload: { listData, count } }) => ({
  ...state,
  userList: {
    ...state.userList,
    listData,
    count,
    isFetching: false,
    isFetched: true,
  },
});

// LEADERBOARD DATA

const setRankListFetching = fetching => state => ({
  ...state,
  rankList: {
    ...state.rankList,
    isFetching: fetching,
  },
});

const setRankList = (state, { payload: { rankData } }) => ({
  ...state,
  rankList: {
    ...state.rankList,
    rankData,
    isFetching: false,
    isFetched: true,
  },
});

const setUserDeleted = (state, { payload: { userId } }) => {
  const newListData = state.userList.listData;
  _remove(newListData, { id: userId });

  const newState = {
    ...state,
    userList: {
      ...state.userList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  // Self user data
  [UserActions.LOGIN_REQUEST]: setUserDataFetching(true),
  [UserActions.LOGIN_FAILURE]: setUserDataFetching(false),
  [UserActions.LOGIN_SUCCESS]: setUserData,

  [UserActions.EDIT_PROFILE_REQUEST]: setUserDataFetching(true),
  [UserActions.EDIT_PROFILE_FAILURE]: setUserDataFetching(false),
  [UserActions.EDIT_PROFILE_SUCCESS]: setUserData,

  [UserActions.FETCH_USER_REQUEST]: setUserDataFetching(true),
  [UserActions.FETCH_USER_FAILURE]: setUserDataFetching(false),
  [UserActions.FETCH_USER_SUCCESS]: setUserData,

  [UserActions.LOGOUT_REQUEST]: setUserDataFetching(true),
  [UserActions.LOGOUT_FAILURE]: setUserDataFetching(false),
  [UserActions.LOGOUT_SUCCESS]: clearUserData,

  // Profile actions
  // [UserActions.USER_PROFILE_REQUEST]: setProfileDataFetching(true),
  // [UserActions.USER_PROFILE_FAILURE]: setProfileDataFetching(false),
  // [UserActions.USER_PROFILE_SUCCESS]: setProfileData,

  // User list
  [UserActions.LIST_USER_REQUEST]: setUserListFetching(true),
  [UserActions.LIST_USER_FAILURE]: setUserListFetching(false),
  [UserActions.LIST_USER_SUCCESS]: setUserList,

  // Leaderboard
  [UserActions.LEADERBOARD_REQUEST]: setRankListFetching(true),
  [UserActions.LEADERBOARD_FAILURE]: setRankListFetching(false),
  [UserActions.LEADERBOARD_SUCCESS]: setRankList,

  [UserActions.DELETE_USER_SUCCESS]: setUserDeleted,
};

export default reducerWithActionMap(actionMap, initialState);
