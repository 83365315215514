import axios from 'axios';

const defaultConfig = {
  baseURL: process.env.RAZZLE_API_URL,
  timeout: 60000,
  withCredentials: true,
};

var instance = null;

export const initializeAxios = config => {
  instance = axios.create(Object.assign(defaultConfig, config));
  instance.interceptors.request.use(( config => {
    var jwt = localStorage.getItem("jwt");
    config.headers['X-Access-Token'] = jwt;
    return config;
  }))
};

const getAxiosInstance = () => {
  if (!instance) {
    initializeAxios();
  }
  return instance;
};

export default getAxiosInstance;
