import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// import { moment } from './config/monent';
import { useLocation } from "react-router";
import Header from "components/Header";
import { FacebookProvider } from "react-facebook";
import SEO from "components/SEO";
import { fetchUser } from "actions/user";
import { useHistory, Redirect } from "react-router-dom";
import { openModal, closeModal } from "actions/modal";
import {
  getProfileData,
  getUserAuthenticated,
  getUserDetails,
} from "selectors/user";
import EmailLoginModal from "components/Auth/EmailLoginModal";
import SignupModal from "components/Auth/SignupModal";
import SocialMediaLoginModal from "components/Auth/SocialMediaLoginSignup";
import ForgotPasswordModal from "components/Auth/ForgotPasswordModal";
import ConnectedSocketProvider from "utils/ConnectedSocketProvider";
import TagManager from 'react-gtm-module'
import { FacebookPixel } from 'react-use-facebook-pixel';
import { clarity } from 'react-microsoft-clarity';

var pixel;

const Layout = ({
  children,
  isAuthenticated,
  openModal,
  closeModal,
  fetchUser,
}) => {
  const history = useHistory();

  useEffect(() => {
    fetchUser();
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize({
        gtmId: process.env.RAZZLE_GTAG_ID
      });
      clarity.init(process.env.RAZZLE_CLARITY_ID);
      const initializeFacebookPixel = async () => {
        pixel = new FacebookPixel({
          pixelID: process.env.RAZZLE_PIXEL_ID,
        });

        pixel.init({});

        setFacebookPixel(pixel);
      };

      initializeFacebookPixel();
    }
  }, []);

  let location = useLocation();
  useEffect(() => {
    if (pixel) {
      pixel.trackEvent('PageView');
    }
  }, [location]);

  return (
    <FacebookProvider appId={process.env.RAZZLE_FACEBOOK_ID}>
      <Helmet>
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="alternate icon" href="/favicon.ico" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="initial-scale=0.86, width=device-width"
        />
      </Helmet>

      <ConnectedSocketProvider>
        {children}
        {!isAuthenticated && (
          <>
            <EmailLoginModal closeModal={closeModal} openModal={openModal} />
            <SignupModal closeModal={closeModal} openModal={openModal} />
            <SocialMediaLoginModal
              closeModal={closeModal}
              openModal={openModal}
            />
            <ForgotPasswordModal
              closeModal={closeModal}
              openModal={openModal}
            />
            {/* <ResetPasswordModal closeModal={closeModal} openModal={openModal} /> */}
          </>
        )}
      </ConnectedSocketProvider>
    </FacebookProvider>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getUserAuthenticated(state),
  profileData: getProfileData(state),
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  closeModal,
  openModal,
  fetchUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
