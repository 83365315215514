import getAxiosInstance from "../config/http";

export const LOGIN_REQUEST = "USER@LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USER@LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USER@LOGIN_FAILURE";

export const login = (loginData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LOGIN_REQUEST,
  });
  try {
    const { data } = await http.post("/auth/signin", loginData);
    localStorage.setItem("jwt", data.token);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        userData: data,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    dispatch({
      type: LOGIN_FAILURE,
    });
    throw e;
  }
};

export const LOGIN_TOKEN_REQUEST = "USER@LOGIN_TOKEN_REQUEST";
export const LOGIN_TOKEN_SUCCESS = "USER@LOGIN_TOKEN_SUCCESS";
export const LOGIN_TOKEN_FAILURE = "USER@LOGIN_TOKEN_FAILURE";

export const loginToken = (type, token) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LOGIN_TOKEN_REQUEST,
  });
  try {
    const {data} = await http.get(`/auth/${type}/token`, {
      params: { access_token: token },
    });
    localStorage.setItem("jwt", data.token);
    dispatch({
      type: LOGIN_TOKEN_SUCCESS,
      payload: {
        userData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: LOGIN_TOKEN_FAILURE,
    });
    throw e;
  }
};

export const LOGOUT_REQUEST = "USER@LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "USER@LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "USER@LOGOUT_FAILURE";

export const logout = () => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LOGOUT_REQUEST,
  });
  try {
    const userData = await http.post("/auth/signout");
    localStorage.removeItem("jwt");
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: LOGOUT_FAILURE,
    });
    throw e;
  }
};

export const SIGNUP_REQUEST = "USER@SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "USER@SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "USER@SIGNUP_FAILURE";

export const signup = (data) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: SIGNUP_REQUEST,
  });
  try {
    const userData = await http.post("/auth/signup", data);
    dispatch({
      type: SIGNUP_SUCCESS,
      payload: {
        userData: userData.data,
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: SIGNUP_FAILURE,
    });
    throw e;
  }
};

export const CONFIRM_REQUEST = "USER@CONFIRM_REQUEST";
export const CONFIRM_SUCCESS = "USER@CONFIRM_SUCCESS";
export const CONFIRM_FAILURE = "USER@CONFIRM_FAILURE";

export const verifyEmail = (token) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CONFIRM_REQUEST,
  });
  try {
    const userData = await http.get(`/auth/confirm`, { params: { token } });
    dispatch({
      type: CONFIRM_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: CONFIRM_FAILURE,
    });
    throw e;
  }
};

export const FORGOT_PASSWORD_REQUEST = "USER@FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "USER@FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "USER@FORGOT_PASSWORD_FAILURE";

export const forgotPassword = (data) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FORGOT_PASSWORD_REQUEST,
  });
  try {
    const userData = await http.post("/auth/forgot", data);
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: {
        userData: userData.data,
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: FORGOT_PASSWORD_FAILURE,
    });
  }
};

export const RESET_PASSWORD_REQUEST = "USER@RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "USER@RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "USER@RESET_PASSWORD_FAILURE";

export const resetPassword = (data) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: RESET_PASSWORD_REQUEST,
  });
  try {
    const userData = await http.post("/auth/reset", data);
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: {
        userData: userData.data,
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: RESET_PASSWORD_FAILURE,
    });
  }
};

export const LIST_USER_REQUEST = "USER@LIST_USER_REQUEST";
export const LIST_USER_SUCCESS = "USER@LIST_USER_SUCCESS";
export const LIST_USER_FAILURE = "USER@LIST_USER_FAILURE";

export const listUsers = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_USER_REQUEST,
  });
  try {
    const userData = await http.get("/users", { params });
    dispatch({
      type: LIST_USER_SUCCESS,
      payload: {
        listData: userData.data.users,
        count: userData.data.count
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_USER_FAILURE,
    });
  }
};

export const REGISTRATION_COMPLETE_REQUEST =
  "USER@REGISTRATION_COMPLETE_REQUEST";
export const REGISTRATION_COMPLETE_SUCCESS =
  "USER@REGISTRATION_COMPLETE_SUCCESS";
export const REGISTRATION_COMPLETE_FAILURE =
  "USER@REGISTRATION_COMPLETE_FAILURE";

export const registrationComplete = (data) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: REGISTRATION_COMPLETE_REQUEST,
  });
  try {
    const userData = await http.post("/users/registration-complete", data);
    dispatch({
      type: REGISTRATION_COMPLETE_SUCCESS,
      payload: {
        listData: userData.data,
      },
    });
    return userData.data;
  } catch (e) {
    dispatch({
      type: REGISTRATION_COMPLETE_FAILURE,
    });
  }
};

export const CHANGE_PASSWORD_REQUEST = "USER@CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "USER@CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "USER@CHANGE_PASSWORD_FAILURE";

export const changePassword = (data) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CHANGE_PASSWORD_REQUEST,
  });
  try {
    const userData = await http.post("/users/me/change-password", data);
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: {
        listData: userData.data,
      },
    });
    return userData.data;
  } catch (e) {
    dispatch({
      type: CHANGE_PASSWORD_FAILURE,
    });
  }
};
export const FETCH_USER_REQUEST = "USER@FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "USER@FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "USER@FETCH_USER_FAILURE";

export const fetchUser = () => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_USER_REQUEST,
  });
  try {
    const { data } = await http.get("/users/me");
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: {
        userData: data,
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: FETCH_USER_FAILURE,
    });
  }
};

export const EDIT_PROFILE_REQUEST = "USER@EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_SUCCESS = "USER@EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "USER@EDIT_PROFILE_FAILURE";

export const editProfile = (data) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: EDIT_PROFILE_REQUEST,
  });
  try {
    const userData = await http.put("/users/me", data);
    dispatch({
      type: EDIT_PROFILE_SUCCESS,
      payload: {
        userData: userData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: EDIT_PROFILE_FAILURE,
    });
  }
};

export const LEADERBOARD_REQUEST = "USER@LEADERBOARD_REQUEST";
export const LEADERBOARD_SUCCESS = "USER@LEADERBOARD_SUCCESS";
export const LEADERBOARD_FAILURE = "USER@LEADERBOARD_FAILURE";

export const getLeaderboard = () => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LEADERBOARD_REQUEST,
  });
  try {
    const rankData = await http.get("/users/leaderboard");
    dispatch({
      type: LEADERBOARD_SUCCESS,
      payload: {
        rankData: rankData.data,
      },
    });
    return rankData.data;
  } catch (e) {
    console.log(e);
    dispatch({
      type: LEADERBOARD_FAILURE,
    });
  }
};


// export const fetchUser = (userId) => async (dispatch) => {
//   const http = getAxiosInstance();
//   dispatch({
//     type: FETCH_USER_REQUEST,
//   });
//   try {
//     const userData = await http.get(`/users/${userId}`);
//     dispatch({
//       type: FETCH_USER_SUCCESS,
//       payload: {
//         userData: userData.data,
//       },
//     });
//   } catch (e) {
//     dispatch({
//       type: FETCH_USER_FAILURE,
//     });
//   }
// };

export const UPDATE_USER_REQUEST = "USER@UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "USER@UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "USER@UPDATE_USER_FAILURE";

export const updateUser = (id, userData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const data = await http.put(`/users/${id}`, userData);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: {
        userData: data,
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_USER_FAILURE,
    });
  }
};

export const DELETE_USER_REQUEST = "USER@DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "USER@DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "USER@DELETE_USER_FAILURE";

export const deleteUser = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_USER_REQUEST,
  });
  try {
    const data = await http.delete(`/users/${id}`);
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: {
        userId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_USER_FAILURE,
    });
  }
};

export const RESTORE_USER_REQUEST = "USER@RESTORE_USER_REQUEST";
export const RESTORE_USER_SUCCESS = "USER@RESTORE_USER_SUCCESS";
export const RESTORE_USER_FAILURE = "USER@RESTORE_USER_FAILURE";

export const restoreUser = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: RESTORE_USER_REQUEST,
  });
  try {
    const { data } = await http.put(`/users/${id}/restore`);
    dispatch({
      type: RESTORE_USER_SUCCESS,
      payload: {
        userId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: RESTORE_USER_FAILURE,
    });
  }
};
