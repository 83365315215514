import _remove from "lodash/remove";
import _keyBy from "lodash/keyBy";
import _findIndex from "lodash/findIndex";
import * as GameActions from "actions/game";
import reducerWithActionMap from "../utils/reducerWithActionMap";

const initialState = {
  isAuthenticated: false,
  gameList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    count: 0,
  },
  leaderboardList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    count: 0,
  },
  gameDetails: {
    isFetching: false,
    isFetched: false,
    gameData: null,
    userMap: {},
  },
  gameStates: {},
  gameResults: {
    isFetched: false,
    isFetching: false,
    gameRes: null,
  }
};

const setGameListFetching = (fetching) => (state) => ({
  ...state,
  gameList: {
    ...state.gameList,
    isFetching: fetching,
  },
});

const setGameList = (state, { payload: { listData, count } }) => ({
  ...state,
  gameList: {
    ...state.gameList,
    listData,
    count,
    isFetching: false,
    isFetched: true,
  },
});

const setGameFetching = (fetching) => (state) => ({
  ...state,
  gameDetails: {
    ...state.gameDetails,
    isFetching: fetching,
  },
});

const setLeaderboardListFetching = (fetching) => (state) => ({
  ...state,
  leaderboardList: {
    ...state.leaderboardList,
    isFetching: fetching,
  },
});

const setLeaderboardList = (state, { payload: { listData, count } }) => ({
  ...state,
  leaderboardList: {
    ...state.leaderboardList,
    listData,
    count,
    isFetching: false,
    isFetched: true,
  },
});

const setGame = (state, { payload: { gameData } }) => {
  const userMap = _keyBy(gameData.users, "id");
  return {
    ...state,
    gameDetails: {
      ...state.gameDetails,
      gameData,
      userMap,
      isFetching: false,
      isFetched: true,
    },
  };
};

const setGameDeleted = (state, { payload: { gameId } }) => {
  const newListData = state.gameList.listData;
  _remove(newListData, { id: gameId });

  return {
    ...state,
    gameList: {
      ...state.gameList,
      listData: newListData,
    },
  };
};

const syncGame = (state, { data }) => ({
  ...state,
  gameStates: {
    ...state.gameStates,
    [data.gameId]: data.sync,
  },
});

const userJoinLobby = (state, { data }) => {
  const newLobbyState = { ...state.gameStates[data.gameId] };
  newLobbyState.users.push(data.user);
  return {
    ...state,
    gameStates: {
      ...state.gameStates,
      [data.gameId]: newLobbyState,
    },
  };
};

const userLeaveLobby = (state, { data }) => {
  const newLobbyState = { ...state.gameStates[data.gameId] };
  _remove(newLobbyState.users, { id: data.user.id });
  return {
    ...state,
    gameStates: {
      ...state.gameStates,
      [data.gameId]: newLobbyState,
    },
  };
};

const removeGame = (state, { data }) => {
  const newListData = { ...state.gameList.listData };
  _remove(newListData.games, { id: data.game.id });
  newListData.count -= 1;
  return {
    ...state,
    gameList: {
      ...state.gameList,
      listData: newListData,
    },
  };
};

const addGame = (state, { data }) => {
  const newListData = { ...state.gameList.listData };
  if (newListData.games) {
    newListData.games.unshift(data.game, );
    newListData.count += 1;
  }
  return {
    ...state,
    gameList: {
      ...state.gameList,
      listData: newListData,
    },
  };
};

const updateGame = (state, { data }) => {
  const newListData = { ...state.gameList.listData };

  const idx = _findIndex(newListData.games, { id: data.game.id });
  if (idx >= 0) {
    newListData.games[idx] = data.game;
  }
  return {
    ...state,
    gameList: {
      ...state.gameList,
      listData: newListData,
    },
  };
};

const setGameResultsFetching = (fetching) => (state) => ({
  ...state,
  gameResults: {
    ...state.gameResults,
    isFetching: fetching,
  },
});

const setGameResults = (state, { payload: { gameRes }} ) => ({
  ...state,
  gameResults: {
    ...state.gameResults,
    isFetching: false,
    isFetched: true,
    gameRes
  },
});

const actionMap = {
  [GameActions.LIST_GAME_REQUEST]: setGameListFetching(true),
  [GameActions.LIST_GAME_SUCCESS]: setGameList,
  [GameActions.LIST_GAME_FAILURE]: setGameListFetching(false),

  [GameActions.DELETE_GAME_SUCCESS]: setGameDeleted,

  [GameActions.FETCH_GAME_REQUEST]: setGameFetching(true),
  [GameActions.FETCH_GAME_SUCCESS]: setGame,
  [GameActions.FETCH_GAME_FAILURE]: setGameFetching(false),

  [GameActions.SYNC]: syncGame,
  [GameActions.GAME_LOBBY_JOIN]: userJoinLobby,
  [GameActions.GAME_LOBBY_LEAVE]: userLeaveLobby,

  [GameActions.GAME_REMOVED]: removeGame,
  [GameActions.GAME_ADDED]: addGame,
  [GameActions.GAME_UPDATED]: updateGame,

  [GameActions.GAME_RESULTS_REQUEST]: setGameResultsFetching(true),
  [GameActions.GAME_RESULTS_SUCCESS]: setGameResults,
  [GameActions.GAME_RESULTS_FAILURE]: setGameResultsFetching(false),

  [GameActions.LIST_LEADERBOARD_REQUEST]: setLeaderboardListFetching(true),
  [GameActions.LIST_LEADERBOARD_SUCCESS]: setLeaderboardList,
  [GameActions.LIST_LEADERBOARD_FAILURE]: setLeaderboardListFetching(false),

};

export default reducerWithActionMap(actionMap, initialState);
