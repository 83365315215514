import getAxiosInstance from 'config/http';

export const LIST_REQUEST = 'CHAT@LIST_REQUEST';
export const LIST_SUCCESS = 'CHAT@LIST_SUCCESS';
export const LIST_FAILURE = 'CHAT@LIST_FAILURE';

export const fetchMessages = (gameId, params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_REQUEST,
  });
  try {
    const chatMessages = await http.get(`/chats/game/${gameId}`, { params });
    dispatch({
      type: LIST_SUCCESS,
      payload: {
        listData: {
          ...chatMessages.data,
          chats: chatMessages.data.chats.reverse(),
        }
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: LIST_FAILURE,
    });
  }
};


export const CREATE_CHAT_REQUEST = 'CHAT@CREATE_REQUEST';
export const CREATE_CHAT_SUCCESS = 'CHAT@CREATE_SUCCESS';
export const CREATE_CHAT_FAILURE = 'CHAT@CREATE_FAILURE';

export const postMessage = (gameId, data) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_CHAT_REQUEST,
  });
  try{
    const messageData = await http.post(`/chats/game/${gameId}`, data);
    dispatch({
      type: CREATE_CHAT_SUCCESS,
      data: messageData.data,
    });
    return messageData.data;
  } catch(e){
    dispatch({
      type: CREATE_CHAT_FAILURE,
    });
  };
};

export const CHAT_CREATED = 'CHAT@CREATE';
