import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { forgotPassword } from "actions/user";
import { openModal, closeModal } from "actions/modal";
import { FORGOT_PASSWORD_MODAL_ID } from "consts/modalIds";

import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import ConnectedDialog from "components/ConnectedDialog";
import x from "img/x.svg";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("invalid_email")
    .required("required_field"),
});

const ForgotPasswordModal = ({
  openModal,
  closeModal,
  modalId,
  forgotPassword,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const closeLoginModal = () => {
    closeModal(FORGOT_PASSWORD_MODAL_ID);
  };

  const submitForm = async (values) => {
    console.log('clicking forgot pass');
    console.log(values);
    let forgotPass = null;
    try {
      forgotPass = await forgotPassword(values);
      closeLoginModal();
      // if (!user.registrationComplete) {
      //   history.push("/editProfile");
      // }
    } catch (e) {
      console.log(e);
      alert(t("request_failed"));
      // throw e;
    }
  };

  return (
    <ConnectedDialog id={FORGOT_PASSWORD_MODAL_ID}>
      <div className="fixed left-0 z-50 top-0 w-full h-full overflow-auto bg-black bg-opacity-25">
        <div className="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 m-0 mx-auto p-6 border-2 bg-white rounded-lg bg-white font-mont p-5">
          <div className="relative rounded-lg bg-white font-mont p-5">
            <div className="absolute top-0 right-0">
              <button
                className="focus:outline-none"
                onClick={() => {
                  closeLoginModal();
                }}
              >
                <img src={x} className="w-5 h-5" />
              </button>
            </div>
            <div className="">
              <div
                className="text-center font-bold text-blue mt-10 mb-10"
                onClose={closeLoginModal}
              >
                {t("request_password_change")}
              </div>
              <Formik
                initialValues={{ email: "" }}
                validationSchema={ForgotPasswordSchema}
                validateOnChange={false}
                onSubmit={submitForm}
              >
                {({ isSubmitting }) => (
                  <Form className="w-72 rounded" autoComplete="on">
                    <label className="text-sm text-left">{t("email")}</label>
                    <Field
                      name="email"
                      id="email"
                      placeholder={t("insert_email")}
                      className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightBlue focus:border-transparent"
                    />
                    <br />
                    <ErrorMessage name='email' render={(error) => t(error)} />
                    <div className="mt-5 container text-left">
                      <button
                        className="bg-blue px-10 py-2 text-white font-bold rounded-md border-2 border-blue text-center focus:outline-none disabled:opacity-50"
                        type="submit"
                        // onClick={loginUser}
                        disabled={isSubmitting}
                      >
                        {t("send_request")}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};

const mapDispatchToProps = {
  forgotPassword,
  openModal,
  closeModal,
};

export default connect(null, mapDispatchToProps)(ForgotPasswordModal);
